import { AxiosResponse } from "axios";
import Axios from "./axios";

class Payment {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    cancel(orderId: string): Promise<AxiosResponse> {
        return this.axios.put(`/payments/${orderId}/cancel`);
    }
}

export default new Payment(Axios);
