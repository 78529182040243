




























import { Vue, Component } from "vue-property-decorator";
import { mdiAlertCircle, mdiClose } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component
export default class SnackAlert extends Vue {
    warningIcon: string = mdiAlertCircle;
    snackbarShow: boolean = true;
    closeIcon: string = mdiClose;

    onClose() {
        addAnalyticsEvent("snack_alert_closed");
        this.snackbarShow = false;
    }
    onActionClick() {
        addAnalyticsEvent("snack_alert_action_clicked");
    }
}
